import React from "react"
import { updateStaticAnswers } from "../helpers/api.js"
import { Button, Form, Input } from "antd"
import withUser from "../hocs/withUser.js"

const SettingsStaticAnswersForm = ({ form, collectionId, updateStaticAnswersState, staticAnswers, collectionIndex, collections, updateCollections, collectionDetails, user: { jwtToken } }) => {
  const { getFieldDecorator } = form

  return (
    <div className={"settings-static-answers-form"}>
      <h4>Add new static answer:</h4>
      <Form onSubmit={ e=> {
        e.preventDefault()
        form.validateFields(async (err, { title, text }) => {
          const newAnswer = { title, text }
          const newList = [...staticAnswers, newAnswer]

          await updateStaticAnswers(collectionId, newList, jwtToken)

          updateCollections([
            ...collections.slice(0, collectionIndex),
            {
              ...collectionDetails,
              staticAnswers: newList
            },
            ...collections.slice(collectionIndex + 1)
          ])

          form.resetFields()

          updateStaticAnswersState(newList)
        })
      }}>
        <Form.Item required={true} label="Answer title">
          {getFieldDecorator("title")(
            <Input placeholder={"Answer title"} />
          )}
        </Form.Item>
        <Form.Item required={true} label="Answer text">
          {getFieldDecorator("text")(
            <Input placeholder={"Answer text"} />
          )}
        </Form.Item>
        <Form.Item>
          <Button type={"secondary"} htmlType={"submit"}>Add answer</Button>
        </Form.Item>
      </Form>
    </div>
  )
}

export default withUser(Form.create({ name: "SettingsCustomTags" })(SettingsStaticAnswersForm))

import React, { useState, useEffect } from "react"
import { Row, Card, Col, Icon, Divider, Input, Form, Button } from "antd"
import { reply, generateAnswer } from "../helpers/api.js"
import { withRouter } from "react-router-dom"
import withUser from "../hocs/withUser.js"
import withApps from "../hocs/withApps.js"
import Spinner from "./Spinner.js"
import withCollections from "../hocs/withCollections.js"
import SingleReviewMessage from "./SingleReviewMessage.js"
import SingleReviewReply from "./SingleReviewReply.js"

const SingleReview = ({ review }) => {
  const [reviewState, updateReviewState] = useState(review)
  const { author, date, rating, title, app_version, content } = reviewState

  const stars = []
  for (let i = 0; i < 5; i++) {
    if (i > rating - 1) {
      stars.push(<Icon type="star" key = { i }/>)
    } else {
      stars.push(<Icon type="star" theme = "filled" key = { i }/>)
    }
    if (i === 4) {
      stars.push(<Divider type="vertical" key={"divider"}/>)
    }
  }

  const Header = <div>
    <Col span = { 18 }>{title}</Col>
    <Col span = { 3 }>{stars}</Col>
    <Col span = {3 }>
      <span>{ date }</span>
    </Col>
  </div>
  return (
    <div className = "single-review">
      <Card title = { Header }>
        <SingleReviewMessage
          author = {author}
          appVersion = { app_version }
          message={content}
        />
        <Row>
          <SingleReviewReply review={reviewState} updateReviewState = { input => updateReviewState(input) }/>
        </Row>
      </Card>
    </div>
  )
}

export default withApps(SingleReview)

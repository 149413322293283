import React from "react"
import { Menu, Icon } from "antd"

const SideApp = ({ app, ...props }) => {
  const { title, kind } = app
  let icon = [<Icon key = {"android"} type="android" />, <Icon key = { "apple" } type="apple" />]
  if (kind === "android") {
    icon = <Icon type="android" />
  } else if (kind === "universal") {
    icon = <Icon type="apple" />
  }

  return (
    <Menu.Item {...props}>
      { icon }{ title }
    </Menu.Item>
  )
}

export default SideApp

import React from "react"
import { Button, Card, Table, Divider } from "antd"
import withUser from "../hocs/withUser.js"
import { updateStaticAnswers } from "../helpers/api.js"

const SettingsStaticAnswersList = props => {
  const {
    staticAnswers,
    collectionDetails,
    user: { jwtToken },
    collectionIndex,
    updateStaticAnswersState,
    updateCollections
  } = props

  const deleteStaticAnswer = async title => {
    const index = staticAnswers.findIndex(({ title: answerTitle }) => answerTitle === title)
    const newAnswers = [...staticAnswers.slice(0, index), ...staticAnswers.slice(index + 1)]

    await updateStaticAnswers(collectionDetails.title_normalized, newAnswers, jwtToken)
    updateCollections(collections => {
      return [
        ...collections.slice(0, collectionIndex),
        {
          ...collectionDetails,
          staticAnswers: newAnswers
        },
        ...collections.slice(collectionIndex + 1)
      ]
    })

    updateStaticAnswersState(newAnswers)
  }

  return (
    <Table dataSource={staticAnswers} columns={[
      {
        title: "Title",
        key: "title",
        dataIndex: "title"
      },
      {
        title: "Text",
        key: "text",
        dataIndex: "text"
      },
      {
        title: "Action",
        key: "delete",
        render: ({ title }) => <a onClick={() => deleteStaticAnswer(title)}>Delete</a>
      }
    ]} pagination = { false }/>
  )
}

export default withUser(SettingsStaticAnswersList)

import React, { useState } from "react"
import { Menu, Dropdown, Icon, List, Drawer } from "antd"

const StaticAnswersDrawer = ({ staticAnswers, setAnswerText, visible, onClose, onSelection }) => {
  const [visibility, updateVisibility] = useState(false)

  const menu = (
    <Menu onClick={({ key }) => {
      setAnswerText(staticAnswers[key])
    }}>
      {
        staticAnswers ? staticAnswers.map((answer, index) => {
          return (
            <Menu.Item key={index}>{ answer }</Menu.Item>
          )
        }) : []
      }
    </Menu>
  )

  return (
    <Drawer
      title="Static answers"
      closable={true}
      onClose={onClose}
      visible={visible}
    >
      <List
        itemLayout="horizontal"
        dataSource={staticAnswers}
        renderItem={item => (
          <List.Item>
            <List.Item.Meta
              title={<a onClick={() => {
                onSelection(item.text)
                onClose()
              }}>{item.title}</a>}
              description={item.text}
            />
          </List.Item>
        )} />
    </Drawer>
  )
}

export default StaticAnswersDrawer

import React, { useState, useEffect } from "react"
import { withRouter } from "react-router-dom"
import InfiniteScroll from "react-infinite-scroller"
import PropTypes from "prop-types"
import { Row } from "antd"
import { isEqual } from "lodash"

import SingleReview from "../components/SingleReview.js"
import Spinner from "../components/Spinner.js"
import Filters from "../components/Filters.js"

import { fetchReviewsByApp } from "../helpers/api.js"

import withApps from "../hocs/withApps.js"
import withUser from "../hocs/withUser.js"

const Reviews = ({ user: { jwtToken }, apps: { byId: appsById }, match: { params } }) => {

  const [filters, setFilters] = useState({})
  const [reviews, setReviews] = useState([])
  const [hasMore, setHasMore] = useState(true)
  const [loadingState, setLoadingState] = useState(false)
  const [skip, setSkip] = useState(0)

  const { appId, collection } = params
  const { ext_id } = appsById[appId]

  const loadMore = async () => {
    setLoadingState(true)
    try {
      const { list: moreReviews, next } = await fetchReviewsByApp({ ext_id, collection, skip, filters, jwtToken })

      Array.prototype.push.apply(reviews, moreReviews)

      setReviews(reviews)
      setHasMore(!!next)
      setSkip(next)

    } catch (err) {
      setHasMore(false)
      setSkip(null)
    }
    setLoadingState(false)
  }

  useEffect( () => {
    //Reset state
    setReviews([])
    setHasMore(true)
    setSkip(0)
  }, [filters])

  return (
    <>
    <Filters
      onChange = { newFilters => {
        //Merges new filters object
        const _filters = { ...filters, ...newFilters }
        //Removes empty filters such as rating 0 or empty text filter
        Object.keys(_filters).forEach(filter => (!(_filters[filter]) || isEqual(_filters[filter], {})) && delete _filters[filter])

        setFilters(_filters)
      }}
      loading = { loadingState }
    />
    <InfiniteScroll
      className = { "reviews" }
      loader = { <Spinner key = { "spinner" }/> }
      loadMore = { loadMore }
      hasMore = { !loadingState && hasMore }>
      {
        reviews.map(review => {
          return (
            <Row key = { review.internal_id }>
              <SingleReview review = { review }/>
            </Row>
          )
        })
      }
    </InfiniteScroll>
    </>
  )
}

Reviews.propTypes = {
  user: PropTypes.object.isRequired,
  apps: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired
}

export default withUser(withApps(withRouter(Reviews)))

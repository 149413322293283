import React, { useState } from "react"
import { Row, Button } from "antd"
import PropTypes from "prop-types"

const TemplateSingleMessage = ({ onDelete, message, previewValues, onEdit }) => {
  const [previewVisibility, setPreviewVisibility] = useState(false)

  const renderPreview = () => {
    if (previewVisibility) {
      return (
        <Row className = { "preview" }>
          <span className = { "preview" }>{ Object.entries(previewValues).reduce((template, [key, value]) => {
            if (value) {
              return template.replace(new RegExp(`{${key}}`, "g"), value)
            } else {
              return template
            }
          }, message) }</span>
        </Row>
      )
    }
  }

  return (
    <li>
      <Row>
        <Button type="secondary" shape="circle" icon="delete" onClick = { onDelete } />
        <Button type="secondary" shape="circle" icon="edit" onClick = {() => onEdit(message)} />
        <Button type="secondary" shape="circle" icon="eye" onClick = {() => setPreviewVisibility(prev => !prev)}/>
        - {message}
      </Row>
      {renderPreview()}
    </li>
  )
}

TemplateSingleMessage.propTypes = {
  onDelete: PropTypes.func.isRequired,
  message: PropTypes.object.isRequired,
  previewValues: PropTypes.object.isRequired,
  onEdit: PropTypes.func.isRequired
}

export default TemplateSingleMessage

import React, { useState, useEffect } from "react"
import { withRouter, Prompt } from "react-router-dom"
import { Row, Col, Button, notification, Input, Icon, Collapse, Modal, Switch } from "antd"
import PropTypes from "prop-types"
import { diff } from "deep-object-diff"

import Spinner from "./Spinner.js"
import AnswersByLang from "./AnswersByLang.js"
import TemplateInput from "./TemplateInput"
import withUser from "../hocs/withUser.js"
import withApps from "../hocs/withApps.js"
import { updateTemplate, deleteTemplate } from "../helpers/api.js"

const { Panel } = Collapse

const SingleTemplate = ({
  template,
  user: { jwtToken },
  apps: { byId: appsById },
  match: { params },
  allTemplates,
  key,
  setTemplates,
  ...props
}) => {
  const [templateBody, updateTemplateBody] = useState(Object.assign(template, { oldName: template.name }))
  const { collection, appId } = params
  const { ext_id: appExtId } = appsById[appId]
  const { name, filters, messages = {} } = templateBody

  const [isSending, setSendingStatus] = useState(false)
  const [showModal, setShowModal] = useState(false)

  const getUnsavedChanges = () => {
    return !!Object.keys(diff({
      name: template.name,
      filters: template.filters,
      messages: template.messages,
      autoReply: template.autoReply
    }, {
      name: templateBody.name,
      filters: templateBody.filters,
      messages: templateBody.messages,
      autoReply: templateBody.autoReply
    })).length
  }


  return (
    <Panel {...props} header={`${templateBody.oldName}${getUnsavedChanges() ? " (unsaved changes)" : ""}`} key = { key } extra = { <Icon type = { "delete" } onClick = { () => setShowModal(true) } /> } className = "single-template">
      {
        (getUnsavedChanges() || templateBody.creation) &&
        <Prompt
          message={"You have unsaved changes for one or more templates. You'll lose this changes. Are you sure?"}
        />
      }
      <Row className = {"heading"}>
        <Col span = { 20 }>
          <Input placeholder="Template name" defaultValue={name} onChange={ ({ target: { value } }) => {
            updateTemplateBody(templateBody => {
              return Object.assign({}, templateBody, { name: value })
            })
          }} />
        </Col>
        <Col span = { 4 }>
          {
            isSending ? <Spinner /> : [
              <Button
                type = { "primary" }
                htmlType="submit"
                onClick={ e => {
                  e.preventDefault()

                  if (allTemplates.filter(({ name }) => name === templateBody.name).length > 1) {
                    notification.error({
                      message: `A template with the name "${templateBody.name}" already exists`
                    })
                  } else {
                    setSendingStatus(true)
                    delete templateBody.creation

                    updateTemplate(collection, appExtId, templateBody, jwtToken)
                      .then(() => {
                        notification.open({
                          message: "Template successfully updated"
                        })
                        setSendingStatus(false)
                        allTemplates.some(({ name }, index) => {
                          if (name === templateBody.oldName) {
                            setTemplates([...allTemplates.slice(0, index), templateBody, ...allTemplates.slice(index + 1)])
                            return true
                          }
                          return false
                        })
                      })
                      .catch(console.error)
                  }
                }}
              >
                { templateBody.creation ? "Save template" : "Update template" }
              </Button>
              // ,
              // templateBody.creation ? "" : <Button
              //   type = { "primary" }
              //   onClick={ () => {
              //     updateTemplateBody(prevTemplateBody => {
              //       return Object.assign(prevTemplateBody, template)
              //     })
              //   }}
              // >
              //   Undo changes (not working)
              // </Button>
            ]
          }
        </Col>
      </Row>
      <Row>
        Autoreply: <Switch checked={templateBody.autoReply} onChange={() => updateTemplateBody(prev => {
          return { ...prev, autoReply: !prev.autoReply }
        })}/>
      </Row>
      {/*<Row className = { "explanation" }>*/}
      {/*  This template matches all the reviews with{" "}*/}
      {/*  {filters.rating && filters.rating.length ? `a rating value that is ${filters.rating.map(({ operator, value, max, min }) => {*/}
      {/*    let string = ` ${operatorsByValue[operator].label.toLowerCase()} `*/}
      {/*    if (operator === "between") {*/}
      {/*      string += `${min} and ${max}`*/}
      {/*    } else if (operator === "=") {*/}
      {/*      string += ` to ${value}`*/}
      {/*    } else {*/}
      {/*      string += `than ${value}`*/}
      {/*    }*/}
      {/*    return string*/}
      {/*  }).join(" OR")}` : ""}*/}
      {/*  { filters.period && filters.period.length ? `${filters.rating.length && filters.rating.length ? " AND" : ""} a date value that is ${filters.period.map(({ operator, value, beginDate, endDate }) => {*/}
      {/*    let string = ` ${operatorsByValue[operator].label.toLowerCase()} `*/}
      {/*    if (operator === "between") {*/}
      {/*      string += `${parseDate(beginDate)} and ${parseDate(endDate)}`*/}
      {/*    } else if (operator === "=") {*/}
      {/*      string += ` to ${parseDate(value)}`*/}
      {/*    } else {*/}
      {/*      string += parseDate(value)*/}
      {/*    }*/}
      {/*    return string*/}
      {/*  }).join(" OR")}` : ""}*/}
      {/*</Row>*/}
      <Row>
        <Col span = {15}>
          <AnswersByLang messages = { messages } updateMessages = { messages => {
            updateTemplateBody(templateBody => {
              delete templateBody.messages
              return Object.assign({ messages }, templateBody)
            })
          } }/>
        </Col>
        <Col span = {9} className = "filters">
          <Row>
            <Row>
              <span className = {"filter-title"}>Rating:</span>
            </Row>
            <Row>
              <TemplateInput type = { "number" } options = {{ min: 1, max: 5, defaultValue: 1 }} onChange = { conditions => {
                updateTemplateBody(currentTemplateBody => {
                  filters.rating = conditions

                  return Object.assign({}, currentTemplateBody, { filters })
                })
              } } currentConditions = { filters.rating }/>
            </Row>
          </Row>
          <Row>
            <span className = {"filter-title"}>Period:</span>
            <TemplateInput type = { "date" } options = {{ defaultValue: new Date() }} onChange = { conditions => {
              updateTemplateBody(currentTemplateBody => {
                filters.period = conditions

                return Object.assign({}, currentTemplateBody, { filters })
              })
            } } currentConditions = { filters.period }/>
          </Row>
        </Col>
      </Row>
      <Modal
        title="Basic Modal"
        visible={showModal}
        onOk={async () => {
          try {
            await deleteTemplate(collection, appExtId, name, jwtToken)
          } catch (err) {
            console.error(err)
            return
          }
          notification.open({
            message: "Template successfully removed"
          })
          setTemplates(templates => {
            return templates.filter(({ name }) => name !== templateBody.name)
          })
        }}
        onCancel={() => setShowModal(false)}
      >
        <p>Are you truly sure to delete this template?</p>
      </Modal>
    </Panel>
  )
}

SingleTemplate.propTypes = {
  template: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  apps: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  allTemplates: PropTypes.array.isRequired,
  setTemplates: PropTypes.func.isRequired,
  key: PropTypes.string.isRequired
}

export default withApps(withUser(withRouter(SingleTemplate)))

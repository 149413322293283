import Amplify from "aws-amplify"

Amplify.configure({
  Auth: {
    region: "eu-west-1",

    userPoolId: "eu-west-1_bSI3ilMCh",

    userPoolWebClientId: "11teds5tc9t1hg8qp9qv0fctka",

    mandatorySignIn: true,

    authenticationFlowType: "USER_PASSWORD_AUTH"
  }
})

export default Amplify

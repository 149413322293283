import React, { useEffect, useState } from "react"
import { Button, Form, Input, notification, Row } from "antd"
import Spinner from "./Spinner.js"
import withUser from "../hocs/withUser.js"
import withApps from "../hocs/withApps.js"
import withCollections from "../hocs/withCollections.js"
import { generateAnswer, reply, updateCustomTags } from "../helpers/api.js"
import { withRouter } from "react-router-dom"
import StaticAnswersDrawer from "./StaticAnswersDrawer.js"

const SingleReviewReplyForm = ({ form, updateReviewState, review, user: { jwtToken }, match: { params }, apps: { byId: appsById }, collections: { list: collections } }) => {
  const { suggested_answer, review_id } = review
  const { appId, collection } = params
  const organizationDetails = collections.find(({ title }) => title === collection)
  const { staticAnswers } = organizationDetails
  const { ext_id: extId } = appsById[appId]
  const { getFieldDecorator } = form

  const [isSending, setSendingStatus] = useState(false)
  const [showStaticAnswersDrawer, updateShowStaticAnswersDrawer] = useState(false)
  const [answerText, setAnswerText] = useState(suggested_answer && suggested_answer.message ? suggested_answer.message : "")

  let lastGeneratedAnswer

  if (suggested_answer) {
    lastGeneratedAnswer = suggested_answer.message
  }

  const replaceText = answer => {
    let result = answer || ""
    if (organizationDetails) {
      ["bug-form", "support-email", "support-phone"].forEach(field => {
        const organizationField = field.split("-").map((word, index) => index === 0 ? word : `${word[0].toUpperCase()}${word.slice(1)}`)
        if (organizationDetails[organizationField]) {
          result = result.replace(new RegExp(`{${field}}`, "g"), organizationDetails[organizationField])
        }
      })
    }
    ["author", "date"].forEach(field => {
      result = result.replace(new RegExp(`{${field}}`, "g"), review[field])
    })
    return result
  }

  const handleReply = async () => {
    setSendingStatus(true)
    form.validateFields(async (err, { answerText }) => {
      if (err) {
        console.error(err)
      }

      const answer = replaceText(answerText)

      try {
        await reply(extId, collection, review_id, answerText, jwtToken)
      } catch (err) {
        console.error(err)
      }
      setSendingStatus(false)

      updateReviewState(prevState => {
        return Object.assign({}, prevState, { answer_text: answer, has_answer: true })
      })
    })
  }

  useEffect(() => {
    const abortController = new AbortController()
    const signal = abortController.signal

    const fetchGeneratedAnswer = async () => {
      if (!suggested_answer) {
        try {
          const suggestedAnswer = await generateAnswer(extId, collection, review_id, lastGeneratedAnswer, jwtToken, signal)
          lastGeneratedAnswer = suggestedAnswer.message
          if (suggestedAnswer && suggestedAnswer.message) {
            setAnswerText(suggestedAnswer.message)
          }
        } catch (err) {
          console.error(err)
        }
      }
    }

    fetchGeneratedAnswer()

    return () => {
      abortController.abort()
    }
  }, [])

  return (
    <Form layout="inline" onSubmit={ async e => {
      e.preventDefault()

      await handleReply()
    }}>
      <Row>
        <Form.Item>
          {
            getFieldDecorator("answerText", { initialValue: replaceText(answerText) })(
              <Input.TextArea id = { "text-area" } maxLength={300} />
            )
          }
        </Form.Item>
      </Row>
      {
        isSending ?
          <Spinner/> :
          <Row className = { "buttons" }>
            {
              staticAnswers &&
              <Button onClick={() => updateShowStaticAnswersDrawer(prev => !prev)}>Static answers</Button>
            }
            <Button
              type="secondary"
              onClick = {async () => {
                const answer = await generateAnswer(extId, collection, review_id, lastGeneratedAnswer, jwtToken)
                lastGeneratedAnswer = answer.message
                setAnswerText(answer.message)
              }}
            >
                Next answer
            </Button>
            <Button
              type="primary"
              htmlType="submit"
            >
                Reply
            </Button>
          </Row>
      }
      {
        staticAnswers &&
          <StaticAnswersDrawer
            staticAnswers = { staticAnswers }
            setAnswerText = { setAnswerText }
            visible = { showStaticAnswersDrawer }
            onClose = { () => updateShowStaticAnswersDrawer(false) }
            onSelection = { text => setAnswerText(text) }
          />
      }
    </Form>
  )
}

export default withRouter(withCollections(withApps(withUser(Form.create({ name: "reviewReply" })(SingleReviewReplyForm)))))

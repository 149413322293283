const { apiUrl } = require(`../config.${process.env.NODE_ENV === "development" ? "local" : "production"}.json`)

export const fetchCollections = async (jwtToken, signal) => {
  const res = await fetch(`${apiUrl}/listCollections`, {
    headers: {
      Authorization: jwtToken
    },
    signal
  })
  if (res.ok) {
    const { collections } = await res.json()
    return collections
  } else {
    const { message } = await res.json()
    throw new Error(message)
  }
}

export const fetchAppsByCollection = async (collection, jwtToken, signal) => {
  const res = await fetch(`${apiUrl}/getAppsByCollection?collection=${collection}`, {
    headers: {
      Authorization: jwtToken
    },
    signal
  })
  if (res.ok) {
    const { apps } = await res.json()
    return apps
  } else {
    const { message } = await res.json()
    throw new Error(message)
  }
}

export const fetchReviewsByApp = async ({ ext_id, collection, limit, skip, filters, jwtToken, signal }) => {
  const res = await fetch(`${apiUrl}/getReviewsByApp?ext_id=${ext_id}&collection=${collection}&limit=${limit || 5}&skip=${skip || 0}&filters=${JSON.stringify(filters)}`, {
    headers: {
      Authorization: jwtToken
    },
    signal
  })
  if (res.ok) {
    return await res.json()
  } else {
    const { message } = await res.json()
    throw new Error(message)
  }
}

export const fetchTemplatesByApp = async (ext_id, collection, jwtToken, signal) => {
  const res = await fetch(`${apiUrl}/getTemplatesByApp?ext_id=${ext_id}&collection=${collection}`, {
    headers: {
      Authorization: jwtToken
    },
    signal
  })
  if (res.ok) {
    const { templates } = await res.json()
    return templates
  } else {
    const { message } = await res.json()
    throw new Error(message)
  }
}

export const generateAnswer = async (ext_id, collection, review_id, previousAnswer, jwtToken, signal) => {
  const res = await fetch(`${apiUrl}/generateAnswer`, {
    method: "POST",
    headers: {
      Authorization: jwtToken,
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      ext_id,
      collection,
      review_id,
      previousAnswer
    }),
    signal
  })
  if (res.ok) {
    const { suggestedAnswer } = await res.json()
    return suggestedAnswer
  } else {
    const { message } = await res.json()
    throw new Error(message)
  }
}

export const reply = async (ext_id, collection, reviewId, answer, jwtToken, signal) => {
  const res = await fetch(`${apiUrl}/reply`, {
    method: "POST",
    headers: {
      Authorization: jwtToken,
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      collection,
      ext_id,
      reviewId,
      answer
    }),
    signal
  })
  if (res.ok) {
    return await res.json()
  } else {
    const { message } = await res.json()
    throw new Error(message)
  }
}

export const updateTemplate = async (collection, ext_id, templateBody, jwtToken, signal) => {
  const res = await fetch(`${apiUrl}/updateTemplate`, {
    method: "POST",
    headers: {
      Authorization: jwtToken,
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      collection,
      ext_id,
      templateBody
    }),
    signal
  })
  if (res.ok) {
    return await res.json()
  } else {
    const { message } = await res.json()
    throw new Error(message)
  }
}

export const deleteTemplate = async (collection, ext_id, templateName, jwtToken, signal) => {
  const res = await fetch(`${apiUrl}/deleteTemplate`, {
    method: "POST",
    headers: {
      Authorization: jwtToken,
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      collection,
      ext_id,
      name: templateName
    }),
    signal
  })
  if (res.ok) {
    return await res.json()
  } else {
    const { message } = await res.json()
    throw new Error(message)
  }
}

export const updateStaticAnswers = async (collection, answers, jwtToken, signal) => {
  const res = await fetch(`${apiUrl}/updateStaticAnswers`, {
    method: "POST",
    headers: {
      Authorization: jwtToken,
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      collection,
      answers
    }),
    signal
  })
  if (res.ok) {
    return await res.json()
  } else {
    const { message } = await res.json()
    throw new Error(message)
  }
}

export const updateCustomTags = async (collection, tags, jwtToken, signal) => {
  const res = await fetch(`${apiUrl}/updateCustomTags`, {
    method: "POST",
    headers: {
      Authorization: jwtToken,
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      collection,
      tags
    }),
    signal
  })
  if (res.ok) {
    return await res.json()
  } else {
    const { message } = await res.json()
    throw new Error(message)
  }
}


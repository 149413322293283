import React from "react"
import { withRouter } from "react-router-dom"
import { Menu, Row } from "antd"
import withCollections from "../hocs/withCollections.js"
import SideCollection from "./SideCollection.js"

const Sidebar = ({ collections: { list: collections }, history: { push } }) => {
  return (
    <div className = "sidebar">
      <Row>
        <h2>Dashboard</h2>
      </Row>
      <Menu
        mode="inline"
        inlineCollapsed={false}
        onClick={({ key }) => push(key)}
      >
        {
          collections.map(collection => {
            const { title_normalized: key } = collection
            return <SideCollection collection={collection} key = { key }/>
          })
        }
      </Menu>
    </div>
  )
}

export default withCollections(withRouter(Sidebar))

import React, { useState, useEffect } from "react"
import { Button, Row, Collapse } from "antd"
import { withRouter } from "react-router-dom"
import SingleTemplate from "../components/SingleTemplate.js"
import withUser from "../hocs/withUser.js"
import withApps from "../hocs/withApps.js"
import { fetchTemplatesByApp } from "../helpers/api.js"

const Templates = ({ user: { jwtToken }, apps: { byId: appsById }, match: { params } }) => {
  const { appId, collection } = params
  const { ext_id } = appsById[appId]
  const [templates, setTemplates] = useState([])

  useEffect(() => {
    const abortController = new AbortController()
    const signal = abortController.signal

    if (!templates.length) {
      fetchTemplatesByApp(ext_id, collection, jwtToken, signal)
        .then(templates => {
          setTemplates(templates)
        })
        .catch(console.error)
    }

    return () => {
      abortController.abort()
    }
  }, [])

  return (
    <div className = "templates">
      <Row>
        <Button
          className = { "add-button" }
          type="secondary"
          onClick={e => {
            e.preventDefault()
            setTemplates((oldTemplates) => [{ name: "", filters: { rating: [], period: [{ operator: ">", value: new Date() }] }, messages: {}, creation: true }, ...oldTemplates])
          }}
        >
          Add template
        </Button>
      </Row>
      <Collapse bordered={false} accordion={true}>
        {
          templates.map((template, index, arr) => {
            return (
              <SingleTemplate template = { template } key = { `${index}-${template.name}` } allTemplates = { arr } updateTemplateName = {templateName => {
                setTemplates(templates => {
                  templates[index].name = templateName
                  return [...templates]
                })
              }} setTemplates = {setTemplates}/>
            )
          })
        }
      </Collapse>
    </div>
  )
}

export default withApps(withUser(withRouter(Templates)))

import React from "react"
import { Menu } from "antd"
import { withRouter } from "react-router-dom"
import withApps from "../hocs/withApps.js"
import SideApp from "./SideApp.js"

const SideCollection = ({ collection, apps: { byCollection }, ...props }) => {
  const { title, title_normalized } = collection
  const apps = byCollection[title_normalized] || []

  delete props.staticContext

  return (
    <Menu.SubMenu title={<h3>{ title }</h3>} {...props}>
      <Menu.SubMenu title={<h4>Apps</h4>}>
        {
          apps.map(app => <SideApp app={app} key = { `/${title_normalized}/${app.id}/reviews` }/>)
        }
      </Menu.SubMenu>
      <Menu.Item key = {`/${title_normalized}/settings`}>
        <h4>Settings</h4>
      </Menu.Item>
    </Menu.SubMenu>
  )
}

export default withApps(withRouter(SideCollection))

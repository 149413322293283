import React, { useState, useRef, useEffect } from "react"
import { withRouter } from "react-router-dom"
import { Button, Col, Form, Row, Select } from "antd"
import PropTypes from "prop-types"
import TextareaAutosize from "react-autosize-textarea"

import withCollections  from "../hocs/withCollections.js"
import TemplateSingleMessage from "./TemplateSingleMessage.js"

const { Option } = Select

const parseDate = date => {
  return date ? `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}` : "{date}"
}

const previewValues = {
  author: "John",
  date: parseDate(new Date())
}

const availableLanguages = ["Italian", "English"]

const AnswersByLang = ({ messages, updateMessages, match: { params }, collections: { list: collections } }) => {
  const [viewableMessages, updateViewableMessage] = useState({ ...messages })
  const [selectedLang, setSelectedLang] = useState("italian")
  const [newAnswerText, updateNewAnswerText] = useState("")

  const textarea = useRef()

  useEffect(() => {
    updateViewableMessage({ ...messages })
  }, [messages])

  const { collection: collectionId } = params
  const collection = collections.find(({ title }) => title === collectionId)
  if (collection) {
    previewValues["bug-form"] = collection.bugForm
    previewValues["support-email"] = collection.supportEmail
    previewValues["support-phone"] = collection.supportPhone
  }

  const handleSingleMessageDelete = messageIndex => {
    viewableMessages[selectedLang] = [...viewableMessages[selectedLang].slice(0, messageIndex), ...viewableMessages[selectedLang].slice(messageIndex + 1)]
    updateMessages(viewableMessages)
  }

  const handleSingleMessageEdit = (message, messageIndex) => {
    viewableMessages[selectedLang] = [...viewableMessages[selectedLang].slice(0, messageIndex), ...viewableMessages[selectedLang].slice(messageIndex + 1)]
    updateViewableMessage(viewableMessages)
    updateNewAnswerText(message)
  }

  const handleSubmit = e => {
    e.preventDefault()

    if (viewableMessages[selectedLang]) {
      viewableMessages[selectedLang].push(newAnswerText)
    } else {
      viewableMessages[selectedLang] = [newAnswerText]
    }
    updateMessages(viewableMessages)
    updateNewAnswerText("")
  }

  const renderViewableMessages = () => {
    if (viewableMessages[selectedLang]) {
      return (
        <ul>
          {
            viewableMessages[selectedLang].map((message, index) => {
              return (
                <TemplateSingleMessage
                  onDelete={() => handleSingleMessageDelete(index)}
                  message={message}
                  key={index}
                  previewValues={previewValues}
                  onEdit={message => handleSingleMessageEdit(message, index)}
                />
              )
            })
          }
        </ul>
      )
    }
  }

  const renderTemplatingParameters = () => {
    return ["author", "date", "bug-form", "support-email", "support-phone"].map((field, index) => {
      return (
        <Button
          type="secondary"
          key = { index }
          onClick = { () => updateNewAnswerText(prevText => {
            const { current: { selectionStart, selectionEnd } } = textarea

            return prevText.slice(0, selectionStart) + `{${field}}` + prevText.slice(selectionEnd)
          })}
        >
          { `{${field}}` }
        </Button>
      )
    })
  }


  return (
    <div className = "messages">
      <Row>
        <Col span = { 10 }>
          <span className = { "title" }>Messages:</span>
        </Col>
        <Col span = { 5 } offset = { 9 }>
          <Select defaultValue={selectedLang} onChange={setSelectedLang}>
            {
              availableLanguages.map(lang => <Option value = {lang.toLowerCase()}>{ lang }</Option>)
            }
          </Select>
        </Col>
      </Row>
      <Row>{renderViewableMessages()}</Row>
      <Row>
        <Form layout="inline" onSubmit={handleSubmit}>
          <Row>
            <Form.Item>
              <TextareaAutosize
                className="ant-input"
                ref = { textarea }
                value = {newAnswerText}
                onChange={e => updateNewAnswerText(e.target.value)}
              />
            </Form.Item>
          </Row>
          <Row>
            <Col offset = { 20 } span = { 4 }>
              <Button type="secondary" htmlType="submit">Add reply</Button>
            </Col>
          </Row>
          <Row>
            <Row>Add templating parameters:</Row>
            <Row>{renderTemplatingParameters()}</Row>
          </Row>
        </Form>
      </Row>
    </div>
  )
}

AnswersByLang.propTypes = {
  messages: PropTypes.object.isRequired,
  updateMessages: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  collections: PropTypes.array.isRequired
}

export default withRouter(withCollections(AnswersByLang))

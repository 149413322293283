import React from "react"
import { Card, Col, Row } from "antd"

const SingleReviewMessage = ({ author, appVersion, message }) => {
  return (
    <div>
      <Row>
        <Col span = {3} offset = {18}>
          <span>{author} { appVersion ? `- v${appVersion}` : ""}</span>
        </Col>
      </Row>
      <Row>
        <p>{ message }</p>
      </Row>
    </div>
  )
}

export default SingleReviewMessage

import React, { useState } from "react"
import withCollections from "../hocs/withCollections.js"
import { Button, Form, Input, Divider } from "antd"
import { withRouter } from "react-router-dom"
import { updateStaticAnswers } from "../helpers/api.js"
import withUser from "../hocs/withUser.js"
import SettingsStaticAnswersList from "./SettingsStaticAnswersList.js"
import SettingsStaticAnswersForm from "./SettingsStaticAnswersForm.js"

const SettingsStaticAnswers = ({ collections: { list: collections, updateCollections }, match: { params: { collection: collectionId } } }) => {
  const collectionIndex = collections.findIndex(({ title_normalized }) => title_normalized === collectionId)
  const collection = collections[collectionIndex]

  const [staticAnswers, updateStaticAnswersState] = useState(collection.staticAnswers || [])

  return (
    <div className = { "settings-static-answers" }>
      <h3>Static answers</h3>
      {
        staticAnswers && staticAnswers.length ?
          <SettingsStaticAnswersList
            staticAnswers={staticAnswers}
            collectionDetails = { collection }
            collectionIndex = { collectionIndex }
            updateCollections = { updateCollections }
            updateStaticAnswersState = { updateStaticAnswersState }
          /> : ""
      }
      <SettingsStaticAnswersForm
        staticAnswers = { staticAnswers }
        updateStaticAnswersState = { updateStaticAnswersState}
        collectionIndex = { collectionIndex }
        collections = { collections }
        updateCollections = { updateCollections }
        collectionId = { collectionId }
        collectionDetails = { collection }
      />
    </div>
  )
}

export default withUser(withRouter(withCollections(SettingsStaticAnswers)))

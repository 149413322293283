import React from "react"
import AppsContext from "../context/apps-context.js"

const withApps = Component => {
  return ({ children, ...props }) => (
    <AppsContext.Consumer>
      {
        apps => <Component {...props} apps = { apps }>{ children }</Component>
      }
    </AppsContext.Consumer>
  )
}

export default withApps

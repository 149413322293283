export const operators = [
  { label: "=", value: "=", interval: false },
  { label: ">", value: ">", interval: false },
  { label: "<", value: "<", interval: false },
  { label: "Between", value: "between", interval: true }
]

export const operatorsByValue = operators.reduce((acc, operator) => {
  acc[operator.value] = operator
  return acc
}, {})

import React, { useState } from "react"
import { Collapse, Rate, Input, Checkbox, Icon } from "antd"
import PropTypes from "prop-types"

const Filters = ({ onChange: setFilters, loading }) => {
  const [selectedFields, setSelectedFields] = useState(["content"])

  const options = [
    { label: 'Content', value: 'content' },
    { label: 'Answer text', value: 'answer-text' },
    { label: 'App version', value: 'app-version' },
    { label: "Author", value: "author" }
  ]

  return (
    <Collapse bordered = { false }>
      <Collapse.Panel key = { "filters" } header = { "Filters" } className = { "filters" }>
        <div className = { "rating" }>
          <span className = { "filter-caption" }>Rating:</span>
          <Rate onChange = { stars => setFilters({ rating: stars }) }/>
        </div>
        <div className = { "string" }>
          <div className = { "fields" }>
            <span className = { "filter-caption" }>String contained in:</span>
            <Checkbox.Group
              options = { options }
              defaultValue = { ["content"] }
              onChange = { setSelectedFields }
            />
          </div>
          <div className = { "text-input" }>
            <Input.Search
              enterButton = { "Filter" }
              loading = { loading }
              placeholder = { "Enter string here" }
              onSearch = { value => setFilters({
                text: selectedFields.reduce((filters, field) => {
                  if (value) {
                    filters[field.replace("-", "_")] = value
                  }
                  return filters
                }, {})
              }) }
            />
          </div>
        </div>
        <div className = { "warning" }>
          <Icon type = { "warning" }/> Filters are applied to the first 1000 reviews
        </div>
      </Collapse.Panel>
    </Collapse>
  )
}

Filters.propTypes = {
  onChange: PropTypes.func,
  loading: PropTypes.bool
}

export default Filters

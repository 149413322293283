import React, { useState, useEffect } from "react"
import { BrowserRouter } from "react-router-dom"
import Header from "../components/Header.js"
import Routes from "./Routes.js"
import Sidebar from "../components/Sidebar.js"
import { withAuthenticator } from "aws-amplify-react"
import { Row, Col, notification } from "antd"
import AppsContext from "../context/apps-context.js"
import CollectionsContext from "../context/collections-context.js"
import { fetchCollections, fetchAppsByCollection } from "../helpers/api.js"
import withUser from "../hocs/withUser.js"
import Spinner from "../components/Spinner.js"

notification.config({
  placement: "bottomRight"
})

const Root = ({ user, ...props }) => {
  const [apps, setApps] = useState()
  const [collections, setCollections] = useState([])

  useEffect(() => {
    const abortController = new AbortController()
    const signal = abortController.signal

    const initialFetch = async () => {
      const collections = await fetchCollections(user.jwtToken, signal)
      setCollections(collections)
      sessionStorage.setItem("collections", JSON.stringify(collections))

      const appsState = { byCollection: {} }

      const promises = collections.reduce((acc, { title_normalized: collection }) => {
        if (!appsState.byCollection[collection] || !appsState.byCollection[collection].length) {
          acc.push(fetchAppsByCollection(collection, user.jwtToken, signal)
            .then(apps => ({ collection, apps })))
        }
        return acc
      }, [])

      const results = await Promise.all(promises)
      const newApps = {
        byCollection: results.reduce((acc, { collection, apps }) => {
          acc[collection] = apps
          return acc
        }, {}),
        byId: results.reduce((acc, { apps }) => {
          apps.forEach(app => {
            const { id } = app
            acc[id] = app
          })
          return acc
        }, {})
      }
      setApps(newApps)
      sessionStorage.setItem("apps", JSON.stringify(newApps))
    }

    initialFetch(apps)

    return () => {
      abortController.abort()
    }
  }, [])

  return (
    <div>
      <AppsContext.Provider value = { apps }>
        <CollectionsContext.Provider value = {{ list: collections, updateCollections: collections => {
          setCollections(collections)
        } }}>
          <BrowserRouter>
            <Row>
              <Header logout={() => {
                props.authData.pool.getCurrentUser().signOut()
                props.onStateChange()
              }}/>
            </Row>
            {
              collections.length && apps ?
                <Row>
                  <Col span = { 6 }>
                    <Sidebar/>
                  </Col>
                  <Col span = { 18 }>
                    <Routes/>
                  </Col>
                </Row> : <Spinner/>
            }
          </BrowserRouter>
        </CollectionsContext.Provider>
      </AppsContext.Provider>
    </div>
  )
}

export default withAuthenticator(withUser(Root))

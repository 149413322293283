import React from "react"
import { Row, Col, Button } from "antd"
import logo from "../logo.svg"
import withUser from "../hocs/withUser.js"

const Header = ({ user, logout }) => {
  const { name } = user

  return (
    <header>
      <Row>
        <Col span = { 6 }>
          <img src = {logo} width={30} height={30} alt = {"logo"}/>
          ReviewBot
        </Col>
        <Col span = { 4 } offset = { 12 }>
          Signed in as: { name }
        </Col>
        <Col span = { 2 }>
          <Button onClick={() => logout()}>Logout</Button>
        </Col>
      </Row>
    </header>
  )
}

export default withUser(Header)

import React from "react"
import { Row } from "antd"
import SettingsCustomTags from "../components/SettingsCustomTags.js"
import SettingsStaticAnswers from "../components/SettingsStaticAnswers.js"

const Settings = () => {
  return (
    <div className={"settings content"}>
      <h1>Settings</h1>
      <Row>
        <SettingsCustomTags />
      </Row>
      <Row>
        <SettingsStaticAnswers />
      </Row>
    </div>
  )
}

export default Settings

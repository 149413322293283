import React, { Component } from "react"

class Home extends Component {
  render() {
    return (
      <div className = { "home content" }>
        Home
      </div>
    )
  }
}

export default Home

import React from "react"
import { getGroups, getJwt, getName } from "../selectors/cognito.js"

const withUser = Component => {
  return ({ children, ...props }) => {
    const user = { groups: getGroups(), jwtToken: getJwt(), name: getName() }

    console.log("User", user)

    return (
      <Component {...props} user={user}>{children}</Component>
    )
  }
}

export default withUser

import React from "react"
import { Route, Switch, Redirect } from "react-router-dom"
import Home from "./Home.js"
import App from "./App.js"
import Settings from "./Settings.js"

const Routes = () => {
  return (
    <Switch>
      <Route key={0} exact path={"/"} component={Home}/>
      <Route key={2} path={"/:collection/settings"} component = { Settings } />
      <Route key={1} exact path={"/:collection/:appId"} render={({ match: {params: { appId, collection }}}) => <Redirect to = {`/${collection}/${appId}/reviews`}/> } />
      <Route key={1} path={"/:collection/:appId"} render={props => <App key = { props.match.params.appId } { ...props } /> } />
      <Route key={3} render={() => <p>404</p>}/>
    </Switch>
  )
}
export default Routes

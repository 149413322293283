import React from "react"
import { Form, Input, notification, Button } from "antd"
import { withRouter } from "react-router-dom"
import withCollections from "../hocs/withCollections.js"
import withUser from "../hocs/withUser.js"
import { updateCustomTags } from "../helpers/api.js"

const SettingsCustomTags = ({ form, collections: { list: collections }, match: { params: { collection: collectionId } }, user: { jwtToken } }) => {
  const { getFieldDecorator } = form
  const collection = collections.find(({ title_normalized }) => title_normalized === collectionId)

  return (
    <div className={"settings-custom-tags"}>
      <h3>Custom tags</h3>
      <Form onSubmit={e=> {
        e.preventDefault()
        form.validateFields(async (err, formTags) => {
          try {
            const tags = Object.entries(formTags).reduce((acc, [key, value]) => {
              if (value) {
                acc[key] = value
              }
              return acc
            }, {})
            await updateCustomTags(collectionId, tags, jwtToken)
          } catch (err) {
            notification.error({
              message: "Tags update failed"
            })
            return
          }
          notification.open({
            message: "Tags successfully updated"
          })
        })
      }}>
        {
          ["bug-form", "support-email", "support-phone"].map((field, index) => {
            const collectionField = field.split("-").map((word, index) => index === 0 ? word : `${word[0].toUpperCase()}${word.slice(1)}`).join("")
            return (
              <Form.Item
                label={`{${field}}`}
                required={false}
                key={index}
              >
                {getFieldDecorator(collectionField, { initialValue: collection[collectionField] ? collection[collectionField] : "" })(
                  <Input placeholder={`Insert {${field}} value`} />
                )}
              </Form.Item>
            )
          })
        }
        <Button htmlType={"submit"}>Save changes</Button>
      </Form>
    </div>
  )
}

export default withUser(withRouter(withCollections(Form.create({ name: "SettingsCustomTags" })(SettingsCustomTags))))

import React from "react"
import {Spin} from "antd"

const Spinner = () => {
  return (
    <Spin tip="Loading..." />
  )
}

export default Spinner

import React, { useEffect, useState } from "react"
import { Button, Card, Form, Input, Row } from "antd"
import { generateAnswer, reply } from "../helpers/api.js"
import Spinner from "./Spinner.js"
import withUser from "../hocs/withUser.js"
import withApps from "../hocs/withApps.js"
import { withRouter } from "react-router-dom"
import SingleReviewReplyForm from "./SingleReviewReplyForm.js"

const SingleReviewReply = ({ review, updateReviewState }) => {
  const { answer_text } = review

  return (
    <div>
      {
        answer_text ?
          <Card bordered={false} className = "reply">
            { answer_text }
          </Card>
          : <SingleReviewReplyForm review = { review } updateReviewState = { updateReviewState }/>
      }
    </div>
  )
}

export default SingleReviewReply

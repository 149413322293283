import React from "react"
import { withRouter, Switch, Route } from "react-router-dom"
import { Breadcrumb, Menu, Row } from "antd"
import withApps from "../hocs/withApps.js"
import Templates from "./Templates.js"
import Reviews from "./Reviews.js"

const App = ({ match: { params }, apps, history: { push, location } }) => {
  const { byId: appsById } = apps
  const { collection, appId } = params
  const appDetails = appsById[appId]

  const { title, icon } = appDetails

  const splittedPathname = location.pathname.split("/")
  const selectedKey = splittedPathname[splittedPathname.length - 1]

  return (
    <div className={"app content"}>
      <Row>
        <div className = { "breadcrumb" }>
          <Breadcrumb>
            <Breadcrumb.Item>
              {/* TODO: mettere titolo collection sensato */}
              <img src = {icon} width={30} height={30} alt=""/>
              <span>{ collection }</span>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              { title }
            </Breadcrumb.Item>
          </Breadcrumb>
        </div>
      </Row>
      <Row>
        <Menu
          onClick={({ key }) => push(`/${collection}/${appId}/${key}`)}
          mode="horizontal"
          selectedKeys={[selectedKey]}
        >
          <Menu.Item key="reviews">
              Reviews
          </Menu.Item>
          <Menu.Item key="templates">
              Templates
          </Menu.Item>
        </Menu>
      </Row>
      <Row>
        <Switch>
          <Route key={1} path={"/:collection/:appId/reviews"} component = { Reviews } />
          <Route key={2} path={"/:collection/:appId/templates"} component = { Templates} />
        </Switch>
      </Row>
    </div>
  )
}

export default withApps(withRouter(App))

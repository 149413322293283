import React from "react"
import { Button, InputNumber, Select, Row, Col, DatePicker } from "antd"
import moment from "moment"
import { operators, operatorsByValue } from "../constants.js"

const { RangePicker } = DatePicker

const { Option } = Select

const TemplateInput = ({ type = "text", onChange, options, currentConditions = []}) => {
  let getInput

  const onChangeHandler = (index, field) => value => {
    if (currentConditions[index] && field !== "operator") {
      currentConditions[index][field] = value
    } else {
      currentConditions[index] = { [field]: value }
      if (field === "operator") {
        if (operatorsByValue[value].interval) {
          if (type === "number") {
            Object.assign(currentConditions[index], { min: options.min, max: options.max })
          } else {
            Object.assign(currentConditions[index], { startDate: new Date().toString(), endDate: new Date().toString() })
          }
        } else {
          Object.assign(currentConditions[index], { value: options.defaultValue })
        }
      }
    }

    onChange(currentConditions)
  }

  if (type === "number") {
    getInput = (condition, index) => {
      if (operatorsByValue[condition.operator].interval) {
        return [
          <Col span = { 5 }>
            <InputNumber min={options.min} max={options.max} defaultValue={options.min} value = { condition.min } onChange={onChangeHandler(index, "min")} />
          </Col>,
          <Col span = { 5 }>
            <InputNumber min={options.min} max={options.max} defaultValue={options.min} value = { condition.max } onChange={onChangeHandler(index, "max")} />
          </Col>
        ]
      } else {
        return (
          <Col span = { 10 }>
            <InputNumber min={options.min} max={options.max} defaultValue={options.defaultValue} value = { condition.value } onChange={onChangeHandler(index, "value")} />
          </Col>
        )
      }
    }
  } else if (type === "date") {
    getInput = (condition, index) => {
      if (operatorsByValue[condition.operator].interval) {
        return [
          <Col span = { 10 }>
            <RangePicker
              value = {[moment(condition.startDate), moment(condition.endDate)]}
              format={"DD/MM/YYYY"}
              onChange={([startDate, endDate]) => {
                onChangeHandler(index, "startDate")(startDate.toString())
                onChangeHandler(index, "endDate")(endDate.toString())
              }}
            />
          </Col>
        ]
      } else {
        return (
          <Col span = { 10 }>
            <DatePicker onChange={onChangeHandler(index, "value")} value={moment(condition.value)} />
          </Col>
        )
      }
    }
  }
  // else {
  //
  // }
  return (
    <div className = "template-input">
      <Row>
        {
          currentConditions.map((condition, index) => {
            return (
              <div className = {`${type}`} key = { index + condition.operator + condition.value + condition.max + condition.min }>
                <Row>
                  <Col span = { 4 }>
                    <Button icon="delete" onClick={e => {
                      e.preventDefault()

                      onChange([...currentConditions.slice(0, index), ...currentConditions.slice(index + 1)])
                    }} />
                  </Col>
                  <Col span = { operatorsByValue[condition.operator].interval && type !== "date" ? 5 : 10 }>
                    <Select defaultValue={condition.operator || operators[0].value } onChange={onChangeHandler(index, "operator")}>
                      {
                        operators.map(({ label, value }) => {
                          return <Option value={value}>{ label }</Option>
                        })
                      }
                    </Select>
                  </Col>
                  { getInput(condition, index) }
                </Row>
              </div>
            )
          })
        }
      </Row>
      <Row>
        <Button icon="plus-circle" onClick={e => {
          e.preventDefault()

          const index = currentConditions.length
          onChangeHandler(index, "operator")("=")
        }}>Add condition</Button>
      </Row>
    </div>
  )
}

export default TemplateInput

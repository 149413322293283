import React from "react"
import CollectionsContext from "../context/collections-context.js"

const withCollections = Component => {
  return ({ children, ...props }) => (
    <CollectionsContext.Consumer>
      {
        collections => <Component { ...props } collections = { collections }>{ children }</Component>
      }
    </CollectionsContext.Consumer>
  )
}

export default withCollections
